/* ResidentSearch.css */

.resident-search-container {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
    box-sizing: border-box; /* Include padding and border in the width calculation */
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  input[type='text'] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
    box-sizing: border-box; /* Include padding and border in the width calculation */
    touch-action: manipulation; /* Disable the zooming behavior on touch devices */
  }
  
  button {
    width: 100%;
    padding: 10px 20px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #45a049;
  }
  
  .no-matching-records {
    color: red;
  }
  
  .search-results {
    list-style-type: none;
    padding: 0;
  }
  
  .search-results li {
    margin-bottom: 10px;
  }
  
  /* Media Queries */
  
  @media screen and (max-width: 480px) {
    .resident-search-container {
      max-width: 100%;
      padding: 10px;
    }
  
    input[type='text'] {
      max-width: 100%;
    }
  }
  